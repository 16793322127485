import { env } from "process";
import dayjs from "dayjs";

export function getBackendURL(functionName: string): string {
  const isLocalEnvironment =
    typeof window !== "undefined" &&
    (window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1");

  let baseUrl: string;

  if (isLocalEnvironment) {
    baseUrl = `http://127.0.0.1:5001/rentabar-3d509/us-central1/${functionName}`;
  } else {
    baseUrl = `https://${functionName}-fvxukauz6q-uc.a.run.app`;
  }

  console.log(
    `Environment: ${isLocalEnvironment ? "local" : "production"}, using base URL for ${functionName}: ${baseUrl}`,
  );

  return baseUrl;
}

import {
  DayOfWeek,
  Review,
  Venue,
  VenueBookedTimeSlot,
} from "../../../shared/models";
import { TimeRange } from "../../../shared/types";
import { GetFullPrice } from "./price_calculator";

export function parseGoogleReviews(
  reviewText: string,
  venueId: string,
): Review[] {
  const reviews: Review[] = [];
  const reviewRegex =
    /^(.+?)(?:\nLocal Guide·\d+ reviews·\d+ photos|\n\d+ reviews·\d+ photos|\n\d+ reviews|\n\d+ photos)?\n(.+?)\n(.+?)(?:\n|$)/gm;
  let match;

  while ((match = reviewRegex.exec(reviewText)) !== null) {
    const [, reviewerName, dateAndRating, comment] = match;
    const ratingMatch = dateAndRating.match(/(\d+)/);
    const rating = ratingMatch ? parseInt(ratingMatch[0], 10) : 5;

    reviews.push({
      ID: "", // We don't have an ID from Google reviews, so leaving it empty
      VenueID: venueId,
      ReviewerName: reviewerName.trim(),
      Rating: rating,
      Comment: comment.trim(),
      CreatedAt: undefined,
    });
  }

  return reviews;
}

const isSectionFullyBooked = (
  sectionBookings: VenueBookedTimeSlot[],
  range: TimeRange,
): boolean => {
  const rangeStartTime = range.startTime.getTime();
  const rangeEndTime = range.endTime.getTime();

  return sectionBookings.some((booking) => {
    const bookingStartTime = new Date(booking.StartTime).getTime();
    const bookingEndTime = new Date(booking.EndTime).getTime();

    return bookingStartTime <= rangeStartTime && bookingEndTime >= rangeEndTime;
  });
};

export const containsContactInfo = (text: string) => {
  // Check for email addresses
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  // Check for phone numbers (various formats)
  const phoneRegex =
    /(?:(?:\+?\d{1,3}[-.\s]?)?(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4})/;
  // Check for phrases requesting contact info
  const contactRequestRegex =
    /(?:send|share|give|text|call|contact|reach|email|phone|whatsapp|telegram|dm|direct message|number)/i;

  return (
    emailRegex.test(text) ||
    phoneRegex.test(text) ||
    (contactRequestRegex.test(text.toLowerCase()) &&
      text.toLowerCase().includes("your")) ||
    text.toLowerCase().includes("me")
  );
};

export const containsEmailOrPhoneNumber = (text: string) => {
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  const phoneRegex =
    /(?:(?:\+?\d{1,3}[-.\s]?)?(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4})/;
  return emailRegex.test(text) || phoneRegex.test(text);
};

export function addOpenGraphMetadata(
  document: Document,
  title: string,
  imageUrl: string,
  description: string,
  siteName: string,
): void {
  // Helper function to create and set meta tags
  const setMetaTag = (property: string, content: string) => {
    let metaTag = document.querySelector(`meta[property="${property}"]`);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("property", property);
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute("content", content);
  };

  // Set Open Graph metadata
  setMetaTag("og:title", title);
  setMetaTag("og:image", imageUrl);
  setMetaTag("og:description", description);
  setMetaTag("og:site_name", siteName);

  // Set title tag
  let titleTag = document.querySelector("title");
  if (!titleTag) {
    titleTag = document.createElement("title");
    document.head.appendChild(titleTag);
  }
  titleTag.textContent = title;

  // Set apple-touch-icon
  let linkTag = document.querySelector('link[rel="apple-touch-icon"]');
  if (!linkTag) {
    linkTag = document.createElement("link");
    linkTag.setAttribute("rel", "apple-touch-icon");
    document.head.appendChild(linkTag);
  }
  linkTag.setAttribute("href", imageUrl);
  document.title = title;
}

export const getAvailableTimeSlots = (
  startTime: Date,
  endTime: Date,
  venue: Venue,
  bookedTimeSlots: Record<string, VenueBookedTimeSlot[]>,
  bookedSlotsBySectionAndDay: Record<
    string,
    Record<string, VenueBookedTimeSlot[]>
  >,
): TimeRange[] => {
  startTime.setMinutes(0, 0);
  endTime.setMinutes(0, 0);

  if (endTime <= startTime) {
    endTime = new Date(endTime.getTime() + 24 * 60 * 60 * 1000);
  }

  const timeRanges: TimeRange[] = [];
  let currentTime = new Date(startTime);
  while (currentTime < endTime) {
    const dayOfWeek = currentTime.toLocaleString("en-US", {
      weekday: "long",
    }) as DayOfWeek;
    const dayAvailability = venue.Availability[dayOfWeek];

    if (
      dayAvailability &&
      dayAvailability.Times &&
      dayAvailability.IsOpen &&
      dayAvailability.Times.length > 0
    ) {
      // Use the new Times array
      for (const timeSlot of dayAvailability.Times) {
        let startTimeForSlot = new Date(currentTime);
        const slotStartTime =
          timeSlot.StartTime instanceof Date
            ? timeSlot.StartTime
            : (timeSlot.StartTime as any).toDate();
        const slotEndTime =
          timeSlot.EndTime instanceof Date
            ? timeSlot.EndTime
            : (timeSlot.EndTime as any).toDate();

        startTimeForSlot.setHours(
          slotStartTime.getHours(),
          slotStartTime.getMinutes(),
          0,
          0,
        );
        let endTimeForSlot = new Date(currentTime);
        endTimeForSlot.setHours(
          slotEndTime.getHours(),
          slotEndTime.getMinutes(),
          0,
          0,
        );

        if (endTimeForSlot <= startTimeForSlot) {
          endTimeForSlot.setDate(endTimeForSlot.getDate() + 1);
        }

        timeRanges.push({
          startTime: startTimeForSlot,
          endTime: endTimeForSlot,
          rangeId: startTimeForSlot.getTime(),
        });
      }
    } else if (dayAvailability && dayAvailability.IsOpen) {
      // Fallback to legacy StartTime/EndTime if Times is not available
      let startTimeForDay = new Date(currentTime);
      let startTimeDate = new Date(dayAvailability.StartTime);
      startTimeForDay.setHours(
        startTimeDate.getHours(),
        startTimeDate.getMinutes(),
        0,
        0,
      );
      let endTimeForDay = new Date(currentTime);
      let endTimeDate = new Date(dayAvailability.EndTime);
      endTimeForDay.setHours(
        endTimeDate.getHours(),
        endTimeDate.getMinutes(),
        0,
        0,
      );

      if (endTimeForDay <= startTimeForDay) {
        endTimeForDay.setDate(endTimeForDay.getDate() + 1);
      }

      timeRanges.push({
        startTime: startTimeForDay,
        endTime: endTimeForDay,
        rangeId: startTimeForDay.getTime(),
      });
    }
    currentTime = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);
  }

  const filteredTimeRanges = timeRanges.filter((range) => {
    const rangeDate = range.startTime.toLocaleDateString("en-US");

    for (const sectionId in venue.Sections) {
      if (venue.Sections[sectionId].OverlapsAllOtherSections) {
        const sectionBookings =
          bookedSlotsBySectionAndDay[sectionId]?.[rangeDate] || [];
        if (isSectionFullyBooked(sectionBookings, range)) {
          return false;
        }
      }
    }
    return true;
  });

  return filteredTimeRanges;
};

export function detectMobile(userAgent: string): boolean {
  const mobileKeywords = [
    "Android",
    "webOS",
    "iPhone",
    "iPad",
    "iPod",
    "BlackBerry",
    "Windows Phone",
  ];

  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
}

export const isStorageAvailable = (
  type: "localStorage" | "sessionStorage" | "cookieStorage",
): boolean => {
  try {
    const storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export const setLocalStorageItem = (key: string, value: string): void => {
  if (typeof window === "undefined") return;

  // Try localStorage first
  if (isStorageAvailable("localStorage")) {
    try {
      localStorage.setItem(key, value);
      return;
    } catch (error) {
      console.warn("localStorage failed:", error);
    }
  }

  // Fallback to sessionStorage
  if (isStorageAvailable("sessionStorage")) {
    try {
      sessionStorage.setItem(key, value);
      return;
    } catch (error) {
      console.warn("sessionStorage failed:", error);
    }
  }

  // Final fallback to cookies
  try {
    document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)};path=/;max-age=31536000`;
  } catch (error) {
    console.error("All storage methods failed:", error);
  }
};

export const getLocalStorageItem = (key: string): string | null => {
  if (typeof window === "undefined") return null;

  // Try localStorage first
  if (isStorageAvailable("localStorage")) {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      console.warn("localStorage failed:", error);
    }
  }

  // Fallback to sessionStorage
  if (isStorageAvailable("sessionStorage")) {
    try {
      return sessionStorage.getItem(key);
    } catch (error) {
      console.warn("sessionStorage failed:", error);
    }
  }

  // Final fallback to cookies
  try {
    const cookies = document.cookie.split(";");
    const cookie = cookies.find((c) =>
      c.trim().startsWith(`${encodeURIComponent(key)}=`),
    );
    if (cookie) {
      return decodeURIComponent(cookie.split("=")[1]);
    }
  } catch (error) {
    console.error("All storage methods failed:", error);
  }

  return null;
};

export const normalizeImageUrl = (url: string): string => {
  if (!url) return "";
  const decodedUrl = decodeURIComponent(url);

  // Handle the new venue image pattern (venues/venue_id/section_id/image.whatever)
  // Extract just the basic path without size modifiers
  if (decodedUrl.startsWith("venues/")) {
    // Match the base path and filename, ignoring any size suffixes
    const match = decodedUrl.match(
      /^(venues\/[^\/]+\/[^\/]+\/[^_]+)(_\d+x\d+)?(\.[^.]+)$/,
    );
    if (match) {
      return `${match[1]}${match[3]}`;
    }
  }

  // Handle traditional URLs
  return decodedUrl
    .replace(/[?#].*$/, "") // Remove query params and hash
    .replace(/(_400x400|_800x800|_1600x1600)/, "") // Remove size suffixes
    .replace(/\s+/g, "%20"); // Normalize spaces
};

/**
 * Simplified version that just returns the original URL
 * Image optimization is now handled entirely by loader.js
 * @param url Original image URL
 * @param size Optional size parameter (kept for backward compatibility)
 * @returns Original URL
 */
export const optimizedImageUrl = (
  url: string,
  size?: "small" | "medium" | "large",
): string => {
  if (!url) return "";
  return url;
};

export const getImageUrls = (urls: string[], desiredSize: string): string[] => {
  // Group URLs by their normalized base URL (without size suffix)
  const urlGroups = urls.reduce(
    (acc, url) => {
      const baseUrl = normalizeImageUrl(url);

      if (!acc[baseUrl]) {
        acc[baseUrl] = [];
      }
      acc[baseUrl].push(url);
      return acc;
    },
    {} as Record<string, string[]>,
  );

  // For each group, prioritize the desired size version or take the base URL
  return Object.entries(urlGroups).map(([baseUrl, variants]) => {
    const versionDesired = variants.find((url) => url.includes(desiredSize));
    return versionDesired || variants[0];
  });
};

export const getAppropriateSectionID = (
  venue: Venue,
  people: number,
): string => {
  const sections = Object.keys(venue.Sections);
  const suitableSections = sections.filter(
    (section) => venue.Sections[section].Capacity >= people,
  );

  if (suitableSections.length === 0) {
    // If no sections can fit the party size, return the section with maximum capacity
    return sections.reduce((maxSection, currentSection) => {
      const currentCapacity = venue.Sections[currentSection].Capacity;
      const maxCapacity = venue.Sections[maxSection].Capacity;

      if (currentCapacity > maxCapacity) {
        return currentSection;
      } else if (currentCapacity === maxCapacity) {
        // If capacities are equal, compare pricing rates to find the cheaper one
        const currentRate =
          venue.PricingRatesPerSection[currentSection]?.RatePerHour || Infinity;
        const maxRate =
          venue.PricingRatesPerSection[maxSection]?.RatePerHour || Infinity;
        return currentRate < maxRate ? currentSection : maxSection;
      } else {
        return maxSection;
      }
    }, sections[0]);
  }

  // Find the smallest suitable section that's also the cheapest
  return suitableSections.reduce((bestSection, currentSection) => {
    const currentCapacity = venue.Sections[currentSection].Capacity;
    const bestCapacity = venue.Sections[bestSection].Capacity;
    const currentRate =
      venue.PricingRatesPerSection[currentSection]?.RatePerHour || Infinity;
    const bestRate =
      venue.PricingRatesPerSection[bestSection]?.RatePerHour || Infinity;

    // If current section is smaller, prefer it
    if (currentCapacity < bestCapacity) {
      return currentSection;
    }
    // If capacities are equal, choose the cheaper one
    else if (currentCapacity === bestCapacity && currentRate < bestRate) {
      return currentSection;
    }
    // Otherwise keep the best section found so far
    return bestSection;
  }, suitableSections[0]);
};

export const calculateEstimatedVenuePricing = (
  venue: Venue,
  people: number,
  startTime: string,
  endTime: string,
  dateRequested: Date,
  sectionID: string,
) => {
  // Use dayjs for date/time handling
  const requestedDate = dayjs(dateRequested);

  // Parse start and end times and combine with the requested date
  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);

  const startDateTime = requestedDate
    .hour(startHour || 0)
    .minute(startMinute || 0);
  const endDateTime = requestedDate.hour(endHour || 0).minute(endMinute || 0);

  // Handle case where end time is earlier than start time (next day)
  const adjustedEndDateTime = endDateTime.isBefore(startDateTime)
    ? endDateTime.add(1, "day")
    : endDateTime;

  const pricingRate =
    venue.PricingRatesPerSection[sectionID] ||
    Object.values(venue.PricingRatesPerSection)[0];
  const minimumBookingBlocks = pricingRate
    ? pricingRate.MinimumBookingDuration
    : 2;

  const includeOpenBar = false;
  const price = GetFullPrice(
    startDateTime.toDate(),
    adjustedEndDateTime.toDate(),
    pricingRate,
    people,
    includeOpenBar,
  );
  const sectionCapacity =
    venue.Sections[sectionID]?.Capacity ||
    Math.min(
      ...Object.values(venue.Sections).map(
        (section) => section.Capacity || Number.MAX_VALUE,
      ),
    );
  const effectivePeople = people > sectionCapacity ? sectionCapacity : people;
  const pricePerPerson = (price / effectivePeople).toFixed(0);

  return {
    price,
    pricePerPerson,
    minimumBookingBlocks,
    effectivePeople,
    maxCapacity: sectionCapacity,
    isMinimumSpend: pricingRate?.TowardsMinimumSpend,
  };
};

export const calculateCheapestVenuePricing = (
  venue: Venue,
  people: number,
  startTime: string,
  endTime: string,
  currentDate: Date,
) => {
  // Get all sections that can fit the people
  const suitableSections = Object.keys(venue.Sections).filter(
    (sectionId) => venue.Sections[sectionId].Capacity >= people,
  );

  // If no suitable sections, use the section with maximum capacity
  if (suitableSections.length === 0) {
    const maxCapacitySection = Object.keys(venue.Sections).reduce(
      (maxSection, currentSection) => {
        return venue.Sections[currentSection].Capacity >
          venue.Sections[maxSection].Capacity
          ? currentSection
          : maxSection;
      },
      Object.keys(venue.Sections)[0],
    );

    return calculateEstimatedVenuePricing(
      venue,
      people,
      startTime,
      endTime,
      currentDate,
      maxCapacitySection,
    );
  }

  // Calculate pricing for each suitable section
  const pricingOptions = suitableSections.map((sectionId) => {
    const pricing = calculateEstimatedVenuePricing(
      venue,
      people,
      startTime,
      endTime,
      currentDate,
      sectionId,
    );
    return {
      sectionId,
      ...pricing,
    };
  });

  // Find the cheapest option
  const cheapestOption = pricingOptions.reduce((cheapest, current) => {
    return current.price < cheapest.price ? current : cheapest;
  }, pricingOptions[0]);

  return {
    ...cheapestOption,
    sectionId: cheapestOption.sectionId,
  };
};

export const calculateMostExpensiveVenuePricing = (
  venue: Venue,
  people: number,
  startTime: string,
  endTime: string,
  currentDate: Date,
) => {
  // Get all sections that can fit the people
  const suitableSections = Object.keys(venue.Sections).filter(
    (sectionId) => venue.Sections[sectionId].Capacity >= people,
  );

  // If no suitable sections, use the section with maximum capacity
  if (suitableSections.length === 0) {
    const maxCapacitySection = Object.keys(venue.Sections).reduce(
      (maxSection, currentSection) => {
        return venue.Sections[currentSection].Capacity >
          venue.Sections[maxSection].Capacity
          ? currentSection
          : maxSection;
      },
      Object.keys(venue.Sections)[0],
    );

    return calculateEstimatedVenuePricing(
      venue,
      people,
      startTime,
      endTime,
      currentDate,
      maxCapacitySection,
    );
  }

  // Calculate pricing for each suitable section
  const pricingOptions = suitableSections.map((sectionId) => {
    const pricing = calculateEstimatedVenuePricing(
      venue,
      people,
      startTime,
      endTime,
      currentDate,
      sectionId,
    );
    return {
      sectionId,
      ...pricing,
    };
  });

  // Find the most expensive option
  const mostExpensiveOption = pricingOptions.reduce(
    (mostExpensive, current) => {
      return current.price > mostExpensive.price ? current : mostExpensive;
    },
    pricingOptions[0],
  );

  return {
    ...mostExpensiveOption,
    sectionId: mostExpensiveOption.sectionId,
  };
};
